"use strict";

let intervalId;

$('.video-portfolio-figure').on('mouseenter', function() {
    // Clear any existing interval to avoid multiple intervals
    clearInterval(intervalId);

    // Get the child items of the current .video-portfolio-figure
    const items = $(this).find('.video-portfolio-gallery-item');
    let currentIndex = 0;

    // Function to show the current item within this specific .video-portfolio-figure
    function showCurrentItem() {
        items.each(function(index) {
            $(this).css('opacity', index === currentIndex ? '1' : '0');
        });
    }

    // Function to go to the next item
    function showNextItem() {
        currentIndex = (currentIndex + 1) % items.length; // Increment index and wrap around
        showCurrentItem();
    }

    // Set a new interval to show the next item every 500 milliseconds
    intervalId = setInterval(showNextItem, 350);
});

// Event handler for mouse leave
$('.video-portfolio-figure').on('mouseleave', function() {
    clearInterval(intervalId); // Clear the interval

    // Get the child items of the current .video-portfolio-figure and hide all
    const items = $(this).find('.video-portfolio-gallery-item');
    items.css('opacity', '0');
});